import { Button, Col, Rate, Row, Table, Tabs } from 'antd'
import type { ColumnType } from 'antd/es/table'
import type { TFunction } from 'i18next'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { AuthContext } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { useStudentTestsApiQuery } from '~/student-utils/api'
import { currentYear } from '~/utils/constants'
import { getComment } from '~/utils/fitnessTest'
import isJapanese from '~/utils/isJapanese'
import {
  TTestResultWithPoint,
  elementaryTestFemale,
  elementaryTestMale,
} from '~/utils/points'
import { getPrefectureAverage } from '~/utils/prefecture-average/average'
import { elementaryStandard } from '~/utils/rank-const'
import {
  TAllTestKey,
  testKeysHasEnduranceRunOnly,
  testKeysHasShuttleRunOnly,
} from '~/utils/test-const'
import { getFormattedMinSeconds } from '~/utils/time'
import { IApiResExams } from '~/utils/types/api/alpha/exams'
import {
  ageFromSchool,
  checkIfIsValidRank,
  computeNextGradeValue,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '../../utils'
import { Rt } from '../common/Rt'
import ScoreRadarChart, { ScoreData } from '../graph/ScoreRadarChart'
import { CaretDownIcon } from '../icons/CaretDownIcon'
import { CaretUpIcon } from '../icons/CaretUpIcon'
import { StarFill } from '../icons/StarFill'
import { StarOutline } from '../icons/StarOutline'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'
import { testsIconImages } from './test-result/utils/constants'
import QuestionTooltip from '../cards/QuestionTooltip'

const { TabPane } = Tabs

const secondaryTestMale = (t: TFunction<'translation', undefined>) => [
  {
    score: '1',
    grip: t('以下', { v: '17' }),
    sitUps: t('以下', { v: '12' }),
    bending: t('以下', { v: '20' }),
    sideJump: t('以下', { v: '29' }),
    enduranceRun: t('以上', { v: '9’21”' }),
    shuttleRun: t('以下', { v: '25' }),
    sprintRun: t('以上', { v: `9.8${t('秒')}` }),
    standingJump: t('以下', { v: '149' }),
    handballThrow: t('以下', { v: '12' }),
  },
  {
    score: '2',
    grip: '18~22',
    sitUps: '13~15',
    bending: '21~27',
    sideJump: '30~36',
    enduranceRun: '8’20”~9’20”',
    shuttleRun: '26~36',
    sprintRun: '9.1~9.7',
    standingJump: '150~169',
    handballThrow: '13~15',
  },
  {
    score: '3',
    grip: '23~27',
    sitUps: '16~18',
    bending: '28~32',
    sideJump: '37~40',
    enduranceRun: '7’31”~8’19”',
    shuttleRun: '37~50',
    sprintRun: '8.5~9.0',
    standingJump: '170~187',
    handballThrow: '16~18',
  },
  {
    score: '4',
    grip: '28~32',
    sitUps: '19~21',
    bending: '33~38',
    sideJump: '41~44',
    enduranceRun: '6’51”~7’30”',
    shuttleRun: '51~62',
    sprintRun: '8.0~8.4',
    standingJump: '188~202',
    handballThrow: '19~21',
  },
  {
    score: '5',
    grip: '33~37',
    sitUps: '22~24',
    bending: '39~43',
    sideJump: '45~48',
    enduranceRun: '6’23”~6’50”',
    shuttleRun: '63~75',
    sprintRun: '7.6~7.9',
    standingJump: '203~217',
    handballThrow: '22~24',
  },
  {
    score: '6',
    grip: '38~42',
    sitUps: '25~26',
    bending: '44~48',
    sideJump: '49~52',
    enduranceRun: '5’56”~6’22”',
    shuttleRun: '76~89',
    sprintRun: '7.3~7.5',
    standingJump: '218~229',
    handballThrow: '25~27',
  },
  {
    score: '7',
    grip: '43~46',
    sitUps: '27~29',
    bending: '49~52',
    sideJump: '53~55',
    enduranceRun: '5’34”~5’55”',
    shuttleRun: '90~101',
    sprintRun: '7.1~7.2',
    standingJump: '230~241',
    handballThrow: '28~30',
  },
  {
    score: '8',
    grip: '47~50',
    sitUps: '30~32',
    bending: '53~57',
    sideJump: '56~59',
    enduranceRun: '5’17”~5’33”',
    shuttleRun: '102~112',
    sprintRun: '6.9~7.0',
    standingJump: '242~253',
    handballThrow: '31~33',
  },
  {
    score: '9',
    grip: '51~55',
    sitUps: '33~34',
    bending: '58~63',
    sideJump: '60~62',
    enduranceRun: '5’00”~5’16”',
    shuttleRun: '113~124',
    sprintRun: '6.7~6.8',
    standingJump: '254~264',
    handballThrow: '34~36',
  },
  {
    score: '10',
    grip: t('以上', { v: '56' }),
    sitUps: t('以上', { v: '35' }),
    bending: t('以上', { v: '64' }),
    sideJump: t('以上', { v: '63' }),
    enduranceRun: t('以下', { v: '4’59”' }),
    shuttleRun: t('以上', { v: '125' }),
    sprintRun: t('以下', { v: '6.6' }),
    standingJump: t('以上', { v: '265' }),
    handballThrow: t('以上', { v: '37' }),
  },
]

const secondaryTestFemale = (t: TFunction<'translation', undefined>) => [
  {
    score: '1',
    grip: t('以下', { v: '13' }),
    sitUps: t('以下', { v: '7' }),
    bending: t('以下', { v: '22' }),
    sideJump: t('以下', { v: '26' }),
    enduranceRun: t('以上', { v: '6’58”' }),
    shuttleRun: t('以下', { v: '14' }),
    sprintRun: t('以上', { v: '11.3' }),
    standingJump: t('以下', { v: '117' }),
    handballThrow: t('以下', { v: '7' }),
  },
  {
    score: '2',
    grip: '14~16',
    sitUps: '8~10',
    bending: '23~29',
    sideJump: '27~31',
    enduranceRun: '6’15”~6’57”',
    shuttleRun: '15~20',
    sprintRun: '10.4~11.2',
    standingJump: '118~131',
    handballThrow: '8~9',
  },
  {
    score: '3',
    grip: '17~19',
    sitUps: '11~12',
    bending: '30~34',
    sideJump: '32~35',
    enduranceRun: '5’43”~6’14”',
    shuttleRun: '21~26',
    sprintRun: '9.9~10.3',
    standingJump: '132~144',
    handballThrow: '10',
  },
  {
    score: '4',
    grip: '20~22',
    sitUps: '13~14',
    bending: '35~39',
    sideJump: '36~38',
    enduranceRun: '5’19”~5’42”',
    shuttleRun: '27~34',
    sprintRun: '9.4~9.8',
    standingJump: '145~156',
    handballThrow: '11',
  },
  {
    score: '5',
    grip: '23~24',
    sitUps: '15~17',
    bending: '40~44',
    sideJump: '39~41',
    enduranceRun: '4’57”~5’18”',
    shuttleRun: '35~43',
    sprintRun: '9.0~9.3',
    standingJump: '157~167',
    handballThrow: '12~13',
  },
  {
    score: '6',
    grip: '25~27',
    sitUps: '18~19',
    bending: '45~49',
    sideJump: '42~44',
    enduranceRun: '4’38”~4’56”',
    shuttleRun: '44~53',
    sprintRun: '8.7~8.9',
    standingJump: '168~178',
    handballThrow: '14~15',
  },
  {
    score: '7',
    grip: '28~29',
    sitUps: '20~22',
    bending: '50~53',
    sideJump: '45~47',
    enduranceRun: '4’20”~4’37”',
    shuttleRun: '54~63',
    sprintRun: '8.4~8.6',
    standingJump: '179~189',
    handballThrow: '16~17',
  },
  {
    score: '8',
    grip: '30~32',
    sitUps: '23~25',
    bending: '54~57',
    sideJump: '48~49',
    enduranceRun: '4’03”~4’19”',
    shuttleRun: '64~75',
    sprintRun: '8.1~8.3',
    standingJump: '190~199',
    handballThrow: '18~19',
  },
  {
    score: '9',
    grip: '33~35',
    sitUps: '26~28',
    bending: '58~62',
    sideJump: '50~52',
    enduranceRun: '3’50”~4’02”',
    shuttleRun: '76~87',
    sprintRun: '7.8~8.0',
    standingJump: '200~209',
    handballThrow: '20~22',
  },
  {
    score: '10',
    grip: t('以上', { v: '36' }),
    sitUps: t('以上', { v: '29' }),
    bending: t('以上', { v: '63' }),
    sideJump: t('以上', { v: '53' }),
    enduranceRun: t('以下', { v: '3’49”' }),
    shuttleRun: t('以上', { v: '88' }),
    sprintRun: t('以下', { v: '7.7' }),
    standingJump: t('以上', { v: '210' }),
    handballThrow: t('以上', { v: '23' }),
  },
]

const secondaryStandard = (t: TFunction<'translation', undefined>) => [
  {
    level: 'A',
    '12': t('以上', { v: '51' }),
    '13': t('以上', { v: '57' }),
    '14': t('以上', { v: '60' }),
    '15': t('以上', { v: '61' }),
    '16': t('以上', { v: '63' }),
    '17': t('以上', { v: '65' }),
    '18': t('以上', { v: '65' }),
    '19': t('以上', { v: '65' }),
  },
  {
    level: 'B',
    '12': '41~50',
    '13': '47~56',
    '14': '51~59',
    '15': '52~60',
    '16': '53~62',
    '17': '54~64',
    '18': '54~64',
    '19': '54~64',
  },
  {
    level: 'C',
    '12': '32~40',
    '13': '37~46',
    '14': '41~50',
    '15': '41~51',
    '16': '42~52',
    '17': '43~53',
    '18': '43~53',
    '19': '43~53',
  },
  {
    level: 'D',
    '12': '22~31',
    '13': '27~36',
    '14': '31~40',
    '15': '31~40',
    '16': '31~41',
    '17': '31~42',
    '18': '31~42',
    '19': '31~42',
  },
  {
    level: 'E',
    '12': t('以下', { v: '21' }),
    '13': t('以下', { v: '26' }),
    '14': t('以下', { v: '30' }),
    '15': t('以下', { v: '30' }),
    '16': t('以下', { v: '30' }),
    '17': t('以下', { v: '30' }),
    '18': t('以下', { v: '30' }),
    '19': t('以下', { v: '30' }),
  },
]

const testMaleColumns = (
  t: TFunction<'translation', undefined>,
): ColumnType<any>[] => [
  {
    title: t('得点'),
    dataIndex: 'score',
    key: 'score',
    className: 'w-10 text-center-f font-black ant-table-male-test small-header',
  },
  {
    title: t('握力（kg）'),
    dataIndex: 'grip',
    key: 'grip',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('上体起こし（回）'),
    dataIndex: 'sitUps',
    key: 'sitUps',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('長座体前屈（cm）'),
    dataIndex: 'bending',
    key: 'bending',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('反復横跳び（点）'),
    dataIndex: 'sideJump',
    key: 'sideJump',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('持久走（分・秒）'),
    dataIndex: 'enduranceRun',
    key: 'enduranceRun',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('20mシャトルラン（回）'),
    dataIndex: 'shuttleRun',
    key: 'shuttleRun',
    className:
      'w-24 h-6 text-center-f font-black ant-table-male-test small-header',
  },
  {
    title: t('50m走（秒）'),
    dataIndex: 'sprintRun',
    key: 'sprintRun',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('立ち幅跳び（cm）'),
    dataIndex: 'standingJump',
    key: 'standingJump',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
  {
    title: t('ボール投げ（m）'),
    dataIndex: 'handballThrow',
    key: 'handballThrow',
    className: 'w-24 h-6 text-center-f font-black ant-table-male-test',
  },
]

const testFemaleColumns = (
  t: TFunction<'translation', undefined>,
): ColumnType<any>[] => [
  {
    title: t('得点'),
    dataIndex: 'score',
    key: 'score',
    className:
      'w-10 text-center-f font-black ant-table-female-test small-header',
  },
  {
    title: t('握力（kg）'),
    dataIndex: 'grip',
    key: 'grip',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('上体起こし（回）'),
    dataIndex: 'sitUps',
    key: 'sitUps',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('長座体前屈（cm）'),
    dataIndex: 'bending',
    key: 'bending',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('反復横跳び（点）'),
    dataIndex: 'sideJump',
    key: 'sideJump',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('持久走（分・秒）'),
    dataIndex: 'enduranceRun',
    key: 'enduranceRun',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('20mシャトルラン（回）'),
    dataIndex: 'shuttleRun',
    key: 'shuttleRun',
    className:
      'w-24 h-6 text-center-f font-black ant-table-female-test small-header',
  },
  {
    title: t('50m走（秒）'),
    dataIndex: 'sprintRun',
    key: 'sprintRun',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('立ち幅跳び（cm）'),
    dataIndex: 'standingJump',
    key: 'standingJump',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
  {
    title: t('ボール投げ（m）'),
    dataIndex: 'handballThrow',
    key: 'handballThrow',
    className: 'w-24 h-6 text-center-f font-black ant-table-female-test',
  },
]

const secondaryStandardColumns = (
  t: TFunction<'translation', undefined>,
): ColumnType<any>[] => [
  {
    title: '段階',
    dataIndex: 'level',
    key: 'level',
    className: 'w-10 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 12 }),
    dataIndex: '12',
    key: '12',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 13 }),
    dataIndex: '13',
    key: '13',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 14 }),
    dataIndex: '14',
    key: '14',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 15 }),
    dataIndex: '15',
    key: '15',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 16 }),
    dataIndex: '16',
    key: '16',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 17 }),
    dataIndex: '17',
    key: '17',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 18 }),
    dataIndex: '18',
    key: '18',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 19 }),
    dataIndex: '19',
    key: '19',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
]

const elementaryStandardColumns = (
  t: TFunction<'translation', undefined>,
): ColumnType<any>[] => [
  {
    title: t('段階'),
    dataIndex: 'level',
    key: 'level',
    className: 'w-10 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 6 }),
    dataIndex: '6',
    key: '6',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 7 }),
    dataIndex: '7',
    key: '7',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 8 }),
    dataIndex: '8',
    key: '8',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 9 }),
    dataIndex: '9',
    key: '9',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 10 }),
    dataIndex: '10',
    key: '10',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
  {
    title: t('歳', { age: 11 }),
    dataIndex: '11',
    key: '11',
    className: 'w-24 h-6 text-center-f font-black ant-table-standards',
  },
]

const tests = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
  isUsingJp: boolean,
) => {
  return {
    grip: {
      title: isUsingJpRuby ? (
        <>
          <ruby>
            握力
            <Rt className="text-super-tiny font-bold">あくりょく</Rt>
          </ruby>
        </>
      ) : (
        t('握力')
      ),
      tab_title: isUsingJpRuby ? (
        <>
          <ruby>
            握力
            <Rt className="text-super-tiny font-bold">あくりょく</Rt>
          </ruby>
        </>
      ) : (
        t('握力')
      ),
      suffix: 'kg',
      try_text: '増えると',
    },
    sitUps: {
      title: isUsingJpRuby ? (
        <>
          <ruby>
            上体
            <Rt className="text-super-tiny font-bold">じょうたいお</Rt>
            起こし
          </ruby>
        </>
      ) : (
        t('上体起こし')
      ),
      tab_title: isUsingJpRuby ? (
        <>
          <ruby>
            上体
            <Rt className="text-super-tiny font-bold">じょうたい</Rt>
          </ruby>
        </>
      ) : (
        t('上体起こし')
      ),
      suffix: t('回'),
      try_text: '増えると',
    },
    bending: {
      title: isUsingJpRuby ? (
        <>
          <ruby>
            長座体前屈
            <Rt className="text-super-tiny font-bold">ちょうざたいぜんくつ</Rt>
          </ruby>
        </>
      ) : (
        t('長座体前屈')
      ),
      tab_title: isUsingJpRuby ? (
        <>
          <ruby>
            長座
            <Rt className="text-super-tiny font-bold">ちょうざ</Rt>
          </ruby>
        </>
      ) : (
        t('長座体前屈')
      ),
      suffix: 'cm',
      try_text: '伸びると',
    },
    shuttleRun: {
      title: '20mシャトルラン',
      tab_title: t('シャトル'),
      suffix: t('回'),
      try_text: '増えると',
    },
    sprintRun: {
      title: isUsingJpRuby ? (
        <span>
          50m
          <ruby>
            走<Rt className="text-super-tiny font-bold">そう</Rt>{' '}
          </ruby>
        </span>
      ) : (
        t('50m走')
      ),
      tab_title: '50m',
      suffix: t('秒'),
      try_text: '速くなると',
    },
    sideJump: {
      title: (
        <ruby>
          反復横跳
          <Rt className="text-super-tiny font-bold">はんぷくよことび</Rt>び
        </ruby>
      ),
      tab_title: isUsingJp ? (
        <ruby>
          反復
          <Rt className="text-super-tiny font-bold">はんぷく</Rt>
        </ruby>
      ) : (
        t('反復')
      ),
      suffix: t('回'),
      try_text: '増えると',
    },
    standingJump: {
      title: (
        <span>
          {isUsingJp ? (
            <ruby>
              立<Rt className="text-super-tiny font-bold">た</Rt> ち
              <ruby>
                幅跳<Rt className="text-super-tiny font-bold">はばと</Rt>{' '}
              </ruby>
              び
            </ruby>
          ) : (
            t('立ち幅とび')
          )}
        </span>
      ),
      tab_title: isUsingJp ? (
        <ruby>
          立幅
          <Rt className="text-super-tiny font-bold">たちはば</Rt>
        </ruby>
      ) : (
        t('立ち幅とび')
      ),
      suffix: 'cm',
      // translated in component
      try_text: '伸びると',
    },
    handballThrow: {
      title: isUsingJp ? (
        <span>
          ボール
          <ruby>
            投<Rt className="text-super-tiny font-bold">な</Rt> げ
          </ruby>
        </span>
      ) : (
        t('ボール')
      ),
      tab_title: t('ボール'),
      suffix: 'm',
      try_text: '伸びると',
    },
    enduranceRun: {
      title: isUsingJp ? (
        <ruby>
          持久走
          <Rt className="text-super-tiny font-bold">じきゅうそう</Rt>
        </ruby>
      ) : (
        t('持久走')
      ),
      tab_title: isUsingJp ? (
        <ruby>
          持久走
          <Rt className="text-super-tiny font-bold">じきゅうそう</Rt>
        </ruby>
      ) : (
        t('持久走')
      ),
      suffix: '',
      try_text: '速くなると',
    },
  }
}

/**
 * Path: /result
 */
const TestResultPage = () => {
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp

  const { me: meData } = useAlphaStore()
  const { data: examsData } = useStudentTestsApiQuery()

  const isMale = meData?.gender === 'MALE'

  const [testResult, setTestResult] = useState<null | TTestResultWithPoint>(
    null,
  )

  const [nextGradeValue, setNextGradeValue] = useState<
    | {
        grade: string
        points: number
      }
    | undefined
  >({
    grade: '-',
    points: 0,
  })

  const [prevTestResult, setPrevTestResult] = useState<
    IApiResExams['result'] | undefined | null
  >(null)

  const [isEnduranceRun, setIsEnduranceRun] = useState(false)

  const [testData, setTestData] = useState({
    test: [],
    column: [],
    standard: [],
    standard_column: [],
  })

  const [showScoreTable, setShowScoreTable] = useState(false)

  const shuttleRunPoint = testResult?.shuttleRun?.points
  const enduranceRunPoint = testResult?.enduranceRun?.points

  const isShowEnduranceRunOnly = isEnduranceRun
    ? enduranceRunPoint > shuttleRunPoint
    : false

  const testKeys = isShowEnduranceRunOnly
    ? testKeysHasEnduranceRunOnly
    : testKeysHasShuttleRunOnly

  const history = useHistory()

  useEffect(() => {
    if (!meData?.school) {
      return
    }

    const school = meData.school
    const isElementarySchool = school.schoolCategoryCode === 'B1'

    if (isElementarySchool) {
      setTestData({
        test: isMale
          ? elementaryTestMale(t).reverse()
          : elementaryTestFemale(t).reverse(),
        column: isMale ? testMaleColumns(t) : testFemaleColumns(t),
        standard: elementaryStandard(t),
        standard_column: elementaryStandardColumns(t),
      })
    } else {
      setTestData({
        test: isMale
          ? secondaryTestMale(t).reverse()
          : secondaryTestFemale(t).reverse(),
        column: isMale ? testMaleColumns(t) : testFemaleColumns(t),
        standard: secondaryStandard(t),
        standard_column: secondaryStandardColumns(t),
      })
    }

    setIsEnduranceRun(schoolHasEnduranceTest(school))
  }, [isMale, meData, t])

  useEffect(() => {
    if (!examsData) return

    const prevTestResult = examsData.find(
      (d) => d.testYear === currentYear - 1,
    )?.result

    const testResult = examsData.find((d) => d.testYear === currentYear)?.result

    setPrevTestResult(prevTestResult)

    if (testResult) {
      const age =
        meData &&
        ageFromSchool(meData.school.schoolCategoryCode, meData.schoolGrade)

      const _testResult = reCalcTestResult(
        testResult,
        age,
        meData?.gender,
        isEnduranceRun,
      )

      setTestResult(_testResult)

      setNextGradeValue(
        computeNextGradeValue(
          _testResult.rank ?? 'E',
          _testResult.points,
          Number.isNaN(age) ? 6 : age,
        ),
      )
    }
  }, [examsData, isEnduranceRun, meData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const scoreData: ScoreData[] = [
    {
      subject: t('握力'),
      personal: testResult?.grip?.points,
      prevPersonal: prevTestResult?.grip?.points,
    },
    {
      subject: t('上体起こし'),
      personal: testResult?.sitUps?.points,
      prevPersonal: prevTestResult?.sitUps?.points,
    },
    {
      subject: t('長座体前屈'),
      personal: testResult?.bending?.points,
      prevPersonal: prevTestResult?.bending?.points,
    },
    ...(isShowEnduranceRunOnly
      ? [
          {
            subject: t('持久走'),
            personal: enduranceRunPoint,
            prevPersonal: prevTestResult?.enduranceRun?.points,
          },
        ]
      : [
          {
            subject: t('20mシャトルラン'),
            personal: shuttleRunPoint,
            prevPersonal: prevTestResult?.shuttleRun?.points,
          },
        ]),
    {
      subject: t('50m走'),
      personal: testResult?.sprintRun?.points,
      prevPersonal: prevTestResult?.sprintRun?.points,
    },
    {
      subject: t('反復横跳び'),
      personal: testResult?.sideJump?.points,
      prevPersonal: prevTestResult?.sideJump?.points,
    },
    {
      subject: t('立ち幅跳び'),
      personal: testResult?.standingJump?.points,
      prevPersonal: prevTestResult?.standingJump?.points,
    },
    {
      subject: t('ボール投げ'),
      personal: testResult?.handballThrow?.points,
      prevPersonal: prevTestResult?.handballThrow?.points,
    },
  ]

  const prefectureAverage = meData
    ? getPrefectureAverage(
        meData.school.prefectureCode,
        meData.gender,
        meData.schoolGrade,
        meData.schoolCategoryCode,
      )
    : false

  const Statistic = ({
    title,
    value,
    prevValue,
    suffix,
    points,
    tryText = '',
    endurance = null,
    showPrefectureAverage = false,
    prefectureAverage = '',
    average = '',
    averageByBirthMonth = ''
  }: {
    title: string | JSX.Element
    value: string | number | undefined | false
    prevValue: number | undefined
    suffix: string
    points: any
    tryText: string
    endurance:
      | null
      | undefined
      | { runningTime: number; runningTimeSeconds: number }

    showPrefectureAverage: boolean
    prefectureAverage: string
    average: string
    averageByBirthMonth: string
  }) => {
    const suffixFontSize =
      !isUsingJp && suffix.length > 4 ? 'text-tiny-i' : 'text-10px'

    const averageFontSize =
      !isUsingJp && endurance ? ' text-10px' : ' text-15px'

    return (
      <div key={`${title}`}>
        <div className="text-xs font-bold mt-1 flex justify-between">
          <div className="w-30">
            <span className={`flex ${isUsingJpRuby ? '' : 'mt-1'}`}>
              {title}
            </span>
          </div>

          {!showPrefectureAverage && (
            <div className="text-start text-10px text-black font-normal text-gray-94 pt-2 mr-16">
              {tryText}
            </div>
          )}

          {showPrefectureAverage && (
            <div className="text-10px text-black font-normal text-gray-94 flex pt-2 min-w-8">
              {tryText}
            </div>
          )}
        </div>

        <Row className="ant-row" style={{ flexFlow: 'unset' }}>
          {endurance ? (
            <Row
              className={`w-24 border-none rounded-5px relative h-8 bg-white justify-between font-black ${
                isUsingJp ? ' text-15px' : ' text-12px'
              }`}
            >
              <Col span={12}>
                <div className="h-full flex items-center justify-center">
                  {endurance.runningTime ?? '-'}
                  <span className={`pl-1${isUsingJp ? '' : ' pr-4'}`}>
                    {t('分')}
                  </span>
                </div>
              </Col>

              <Col span={12}>
                <div className="h-full flex items-center justify-center">
                  {endurance.runningTimeSeconds ?? '-'}
                  <span className="pl-1">{t('秒')}</span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row
              align="bottom"
              className="w-24 border-none rounded-5px relative h-8 bg-white"
            >
              <Col span={24}>
                <div className="h-full flex items-center justify-center text-15px font-black">
                  {value ?? '-'}
                </div>

                <div
                  className={`font-black absolute right-1 bottom-0.5 ${suffixFontSize}`}
                >
                  {suffix}
                </div>
              </Col>
            </Row>
          )}

          {value && prevValue ? (
            <div className="flex flex-col w-7 h-8 justify-center items-center text-10px text-gray-94">
              {value > prevValue ? CaretUpIcon : CaretDownIcon}
              {`${value > prevValue ? '＋' : 'ー'}${value - prevValue}`}
            </div>
          ) : (
            <div className="flex flex-col w-7 h-8 justify-center items-center text-10px text-gray-94">
              ー
            </div>
          )}

          <div className="w-24 rounded-5px drop-shadow-md h-8 border border-primary">
            <div
              className={`h-full flex items-center justify-center font-black text-black ${averageFontSize}`}
            >
              {average}
            </div>
          </div>

          <div className="flex flex-col w-7 h-8 justify-center items-center text-10px text-gray-94" />

          <div className="w-24 rounded-5px drop-shadow-md h-8 border border-primary">
            <div
              className={`h-full flex items-center justify-center font-black text-black ${averageFontSize}`}
            >
              {averageByBirthMonth}
            </div>
          </div>
          <div className="flex flex-col w-7 h-8 justify-center items-center text-10px text-gray-94" />

          {showPrefectureAverage && (
            <>
              <div className="w-20 rounded-5px drop-shadow-md h-8 border border-primary">
                <div
                  className={`h-full flex items-center justify-center font-black text-black ${averageFontSize}`}
                >
                  {prefectureAverage}
                </div>
              </div>

              <div className="flex flex-col w-7 h-8 justify-center items-center text-10px text-gray-94" />
            </>
          )}

          <div className="w-20 rounded-5px drop-shadow-md h-8 border border-primary">
            <div className="h-full flex items-center justify-center text-15px font-black text-black">
              {points}
            </div>
          </div>
        </Row>
      </div>
    )
  }

  const commentsTable2 = () => (
    <>
      <Tabs type="card" className="ant-tabs-custom h-full">
        {testKeys.map((key: TAllTestKey, index: number) => {
          const result = testResult?.[key]

          const test = tests(t, isUsingJpRuby, isUsingJp)[key]
          const { tab_title } = test

          const comment = result
            ? getComment(result.rating, testResult.age, key)
            : undefined

          return result ? (
            <TabPane tab={tab_title} key={`${index}`}>
              <div className="p-16 space-y-2 bg-white h-full">
                <Row justify="center" align="middle">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <div
                        key={`grip-rating-fill-${index}-${i}`}
                        className="text-yellow-500"
                      >
                        {i < result.rating ? <StarFill /> : <StarOutline />}
                      </div>
                    ))}
                </Row>
                <Row
                  justify="center"
                  align="middle"
                  className="font-bold text-black"
                >
                  {comment ? t(comment) : ''}
                </Row>
              </div>
            </TabPane>
          ) : (
            ''
          )
        })}
      </Tabs>
    </>
  )

  const currentTests = isShowEnduranceRunOnly
    ? {
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: t('回'),
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs">ちょうざたいぜんくつ</rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横と</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              び
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: t('点'),
        },
        enduranceRun: { title: t('持久走'), suffix: '' },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: t('秒'),
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: { title: t('ボール投げ'), suffix: 'm' },
      }
    : {
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: t('回'),
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs">ちょうざたいぜんくつ</rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: t('点'),
        },
        shuttleRun: { title: t('20mシャトルラン'), suffix: t('回') },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: t('秒'),
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: {
          title: isUsingJpRuby ? (
            <span>
              ボール
              <ruby>
                <span>投</span>
                <rt className="font-normal text-xxs">な</rt>
              </ruby>
              げ
            </span>
          ) : (
            t('ボール投げ')
          ),
          suffix: 'm',
        },
      }

  const onShowScoreTable = () => {
    setShowScoreTable(!showScoreTable)
  }

  const testResultTable = () => {
    return testKeys.map((key: TAllTestKey) => {
      const result = testResult?.[key]
      const prevResult = prevTestResult?.[key]

      const title = currentTests[key]!.title
      const test = tests(t, isUsingJpRuby, isUsingJp)[key]
      const { suffix, try_text } = test

      const points = result?.points || '-'

      let resultValue: string | number

      const value = result?.value
      if (key === 'sitUps') {
        resultValue = value ?? '-'
      } else {
        resultValue =
          value !== undefined && value !== null && value !== '' ? value : '-'
      }

      const prevResultValue = prevResult?.value
      let fullTryText = ''

      if (result?.nextPoints) {
        // recommendation for the next point
        const nextPoints = Math.abs(result.nextPoints)
        const point = result.points + 1

        if (isUsingJp) {
          if (key === 'enduranceRun') {
            const toTime = getFormattedMinSeconds(nextPoints, t)

            fullTryText = `${toTime}${suffix}${try_text}${point}点だ！`
          } else if (key === 'sprintRun') {
            fullTryText = `${nextPoints.toFixed(
              1,
            )}${suffix}${try_text}${point}点だ！`
          } else {
            fullTryText = `${nextPoints}${suffix}${try_text}${point}点だ！`
          }
        } else {
          if (key === 'enduranceRun') {
            fullTryText = t(`${try_text}+1点だ！`, {
              result: getFormattedMinSeconds(nextPoints, t),
              point,
              suffix,
            })
          } else if (key === 'sprintRun') {
            fullTryText = t(`${try_text}+1点だ！`, {
              result: `${nextPoints.toFixed(1)}`,
              point,
              suffix,
            })
          } else {
            fullTryText = t(`${try_text}+1点だ！`, {
              result: `${nextPoints}`,
              point,
              suffix,
            })
          }
        }
      }

      const average = (() => {
        if (result?.average) {
          if (key === 'enduranceRun') {
            return getFormattedMinSeconds(result.average, t)
          }

          return result.average
        }

        return '-'
      })()

      const averageByBirthMonth = (() => {
        if (result?.averageByBirthMonth) {
          if (key === 'enduranceRun') {
            return getFormattedMinSeconds(result.averageByBirthMonth, t)
          }

          return result.averageByBirthMonth
        }

        return '-'
      })()

      const prefectureAverageStr = (() => {
        if (prefectureAverage?.[key]) {
          if (key === 'enduranceRun') {
            return getFormattedMinSeconds(prefectureAverage[key], t)
          }

          return prefectureAverage[key]
        }

        return '-'
      })()

      return (
        <Statistic
          key={key}
          title={title}
          value={
            result?.points > 0 && !result?.isNotMeasurable ? resultValue : '-'
          }
          prevValue={prevResultValue}
          suffix={suffix}
          points={points}
          tryText={fullTryText}
          showPrefectureAverage={!!prefectureAverage}
          prefectureAverage={prefectureAverageStr}
          average={average}
          averageByBirthMonth={averageByBirthMonth}
          endurance={
            key === 'enduranceRun'
              ? (result as { runningTime: number; runningTimeSeconds: number })
              : null
          }
        />
      )
    })
  }

  const ratingTable = () => {
    return (
      <>
        <div className="show-pc">
          <Row style={{ marginBottom: 6 }}>
            <Col span={5}>
              <span className="rating-title">{t('種目')}</span>
            </Col>
            <Col span={5}>
              <span className="rating-title">{t('評価')}</span>
            </Col>
            <Col span={4} className="text-center">
              <span className="rating-title">{t('Video')}</span>
            </Col>
            <Col span={10}>
              <span className="rating-title">{t('コメント')}</span>
            </Col>
          </Row>

          {testKeys.map((key: TAllTestKey) => (
            <Row
              className="my-1"
              style={{ background: '#FFFFFF', borderRadius: 4 }}
              key={key}
            >
              <Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
                <div className="flex items-center">
                  <div
                    className="flex justify-center"
                    style={{
                      width: 45,
                      height: 48,
                      margin: '3px 15px 3px 4px',
                    }}
                  >
                    <img
                      src={testsIconImages[key]}
                      alt=""
                      style={{ objectFit: 'scale-down' }}
                    />
                  </div>
                  <span className="title-test">{currentTests[key]!.title}</span>
                </div>
              </Col>
              <Col span={5}>
                <div className="flex items-center" style={{ height: '100%' }}>
                  <Rate
                    disabled
                    defaultValue={testResult ? testResult[key]?.rating : 0}
                  />
                </div>
              </Col>
              <Col
                span={4}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  className="icon-play-video"
                  onClick={() => {
                    history.push('/videos')
                  }}
                >
                  <img src="/images/play_circle.png" alt="" />
                </div>
              </Col>

              <Col span={10}>
                <span className="rating-comment">
                  {testResult?.[key]
                    ? t(getComment(testResult[key].rating, testResult.age, key))
                    : ''}
                </span>
              </Col>
            </Row>
          ))}
        </div>

        <div className="show-sp">
          <div className="grid grid-cols-4 list-exam-graph mb-6 mt-0">
            {testKeys.map((key: TAllTestKey) => {
              return (
                <div
                  key={key}
                  className={`${
                    showRating === key ? 'exam-active' : ''
                  } text-center`}
                  onClick={() => setShowRating(key)}
                  style={key === 'shuttleRun' ? { fontSize: 10 } : {}}
                >
                  {currentTests[key]!.title}
                </div>
              )
            })}
          </div>
          {testKeys.map((key: TAllTestKey) => (
            <div className={showRating === key ? 'block' : 'hidden'} key={key}>
              <div className="flex justify-between items-center">
                <span className="rating-title">{t('評価')}</span>
                <span className="rating-title">{t('Video')}</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex" style={{ height: '100%' }}>
                  <Rate
                    disabled
                    defaultValue={testResult ? testResult[key]?.rating : 0}
                  />
                </div>
                <div
                  className="icon-play-video"
                  onClick={() => {
                    history.push('/videos')
                  }}
                >
                  <img src="/images/play_circle.png" alt="" />
                </div>
              </div>
              <div>
                <span className="rating-title">{t('コメント')}</span>
              </div>
              <div className="mt-3">
                <span className="rating-comment">
                  {testResult?.[key]
                    ? t(getComment(testResult[key].rating, testResult.age, key))
                    : ''}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  const titleClassName = `h-7 text-center font-black border-b-2 border-primary ${
    isUsingJp ? '' : 'h-12'
  }`

  const resTitleClassName = `w-28 ${titleClassName}`

  const pointTitleClassName = `w-24 ${titleClassName}`

  const [showResult, setShowResult] = useState<1 | 2 | 3>(1)

  const isShowRanking = checkIfIsValidRank(testResult, isEnduranceRun)
  const checkActive = (state: 1 | 2 | 3) => {
    return state === showResult
  }

  const nextRankPointEle = isShowRanking &&
    nextGradeValue?.grade &&
    nextGradeValue.points && (
      <div className="w-30 h-14 rounded-5px mt-3 px-3 py-1 bg-white text-black speech-bubble-left">
        <p className="relative z-10">
          {t('{{rank}}評価まであと{{point}}点だ', {
            rank: nextGradeValue.grade,
            point: nextGradeValue.points,
          })}
        </p>
      </div>
    )

  const resultTable = () => {
    return (
      <>
        <Row justify="center" className="relative form-result-item">
          <div className="w-13/20 pr-3">
            <Row className="result-table pl-3">
              <Col span={14} className="pr-3">
                <Row
                  className={`pt-4 ${isUsingJp ? '' : ' pb-4'}`}
                  style={{ flexFlow: 'unset' }}
                >
                  <div className={pointTitleClassName}>
                    <span>
                      {isUsingJpRuby ? (
                        <>
                          <ruby>
                            <span>自分</span>
                            <rt className="font-normal text-xxxs">じぶん</rt>
                          </ruby>
                          の
                          <ruby>
                            <span>結果</span>
                            <rt className="font-normal text-xxxs">けっか</rt>
                          </ruby>
                        </>
                      ) : (
                        <ruby>
                          <span>{t('自分の結果')}</span>
                        </ruby>
                      )}
                    </span>
                  </div>
                  <div className={`text-11px w-24 ${titleClassName} ml-7`}>
                    <ruby>
                      <span>{t('全国学年平均')}</span>
                      {isUsingJpRuby ? (
                        <rt className="font-normal text-xxxs">
                          ぜんこくがくねんへいきん
                        </rt>
                      ) : null}
                    </ruby>
                  </div>
                  <div className={`text-11px w-24 ${titleClassName} ml-7 relative`}>
                    <ruby>
                      <span>{t('全国月齢平均')}</span>
                      {isUsingJpRuby ? (
                        <rt className="font-normal text-xxxs">
                          ぜんこくげつれいへいきん
                        </rt>
                      ) : null}
                    </ruby>
                    <div className="absolute" style={{ right: -20, top: 0 }}>
                      <QuestionTooltip
                        TitleChild={
                          <span>
                            {isUsingJpRuby ? <ruby>
                                <ruby><span>生</span><rt className="font-normal text-xxs">う</rt></ruby>
                                まれた
                                <ruby><span>月</span><rt className="font-normal text-xxs">つき</rt></ruby>
                                ごとの
                                <ruby><span>平均値</span><rt className="font-normal text-xxs">へいきんち</rt></ruby>
                                を
                                <ruby><span>調</span><rt className="font-normal text-xxs">しら</rt></ruby>
                                べたものだよ。
                              </ruby>
                              : t('生まれた月ごとの平均値を調べたものだよ。')}
                          </span>
                        }
                        placement="top"
                        innerOverlayCss={{
                          width: 305,
                          padding: 20,
                        }}
                      />
                    </div>
                  </div>
                  {!!prefectureAverage && (
                    <div className={`w-20 ${titleClassName} ml-7`}>
                      <ruby>
                        <span>{t('県平均')}</span>
                        {isUsingJpRuby ? (
                          <rt className="font-normal text-xxs">
                            けん へいきん
                          </rt>
                        ) : null}
                      </ruby>
                    </div>
                  )}
                  <div className={`w-20 ${titleClassName} ml-7`}>
                    <ruby>
                      <span>{t('得点')}</span>
                      {isUsingJpRuby ? (
                        <rt className="font-normal text-xxs">とくてん</rt>
                      ) : null}
                    </ruby>
                  </div>
                </Row>
                {testResultTable()}
              </Col>

              <Col span={10}>
                <div className="show-pc">
                  <Row
                    justify="space-between"
                    className="ant-row gap-12 mb-2 mt-4"
                  >
                    <div className={resTitleClassName}>
                      <ruby>
                        {t('総合評価')}
                        {isUsingJpRuby ? (
                          <rt className="font-normal text-xxs">
                            そうごうひょうか
                          </rt>
                        ) : null}
                      </ruby>
                    </div>

                    <div className={resTitleClassName}>
                      <ruby>
                        {t('総合得点')}
                        {isUsingJpRuby ? (
                          <rt className="font-normal text-xxs">
                            そうごうとくてん
                          </rt>
                        ) : null}
                      </ruby>
                    </div>
                  </Row>

                  <Row justify="space-between" className="ant-row gap-12">
                    {isShowRanking ? (
                      <div
                        className="w-28 h-28 flex justify-center items-center text-2xl font-black pb-3.5"
                        style={{
                          background: `url(/images/${
                            testResult?.rank?.toLowerCase() === 'a'
                              ? 'GoldMedal'
                              : 'SilverMedal'
                          }.png) no-repeat center center`,
                        }}
                      >
                        {testResult?.rank ?? '-'}
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className="w-28 h-28 text-center">
                      <div className="w-28 h-10 rounded-5px drop-shadow-md bg-warn text-xl font-black">
                        <div className="h-full flex items-center justify-center text-15px font-black text-black">
                          {testResult?.points ?? '-'}
                        </div>
                      </div>

                      {nextRankPointEle}
                    </div>
                  </Row>
                </div>

                <div className="show-sp">
                  <div className="gap-12 mt-4 mb-2 justify-center flex">
                    <div className={resTitleClassName}>
                      <ruby>
                        {t('総合評価')}
                        {isUsingJpRuby ? (
                          <rt className="font-normal text-xxs">
                            そうごうひょうか
                          </rt>
                        ) : null}
                      </ruby>
                    </div>
                    <div className={resTitleClassName}>
                      <ruby>
                        {t('総合得点')}
                        {isUsingJpRuby ? (
                          <rt className="font-normal text-xxs">
                            そうごうとくてん
                          </rt>
                        ) : null}
                      </ruby>
                    </div>
                  </div>
                  <div className="ant-row gap-12 justify-center flex">
                    {isShowRanking ? (
                      <div
                        className="w-28 h-28 flex justify-center items-center text-2xl font-black pb-3.5"
                        style={{
                          background: `url(/images/${
                            testResult?.rank?.toLowerCase() === 'a'
                              ? 'GoldMedal'
                              : 'SilverMedal'
                          }.png) no-repeat center center`,
                        }}
                      >
                        {testResult?.rank ?? '-'}
                      </div>
                    ) : (
                      <div className="w-28 h-28" />
                    )}
                    <div className="w-28 h-28 text-center">
                      <div className="w-28 h-10 rounded-5px drop-shadow-md bg-warn text-xl font-black">
                        <div className="h-full flex items-center justify-center text-15px font-black text-black">
                          {testResult?.points ?? '-'}
                        </div>
                      </div>
                      {nextRankPointEle}
                    </div>
                  </div>
                </div>
                <div className='show-pc'>
                  <Row
                    justify="space-between"
                    className="ant-row gap-12 mt-4 mb-2"
                  >
                    <div className="w-30 h-7 text-center font-black border-b-2 border-primary">
                      {t('レーダーチャート')}
                    </div>

                    <div className="w-30 h-7" />
                  </Row>
                  <div className="result-graph">
                    <Tabs type="card" className="ant-tabs-custom">
                      <TabPane
                        tab={
                          isUsingJpRuby ? (
                            <ruby>
                              得点<Rt className="text-super-tiny">とくてん</Rt>
                            </ruby>
                          ) : (
                            <ruby>{t('得点')}</ruby>
                          )
                        }
                        key="1"
                      >
                        <ScoreRadarChart
                          title=""
                          data={scoreData}
                          forPrinting={false}
                          border={false}
                          padding={1}
                          outerRadius={90}
                          size={{
                            height: 250,
                            width: '100%',
                          }}
                          hasPrevData={false}
                        />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className='w-7/20 show-sp'>
            <Row
              justify="space-between"
              className="ant-row gap-12 mt-4 mb-2"
            >
              <div className="w-30 h-7 text-center font-black border-b-2 border-primary">
                {t('レーダーチャート')}
              </div>

              <div className="w-30 h-7" />
            </Row>
            <div className="result-graph">
              <Tabs type="card" className="ant-tabs-custom">
                <TabPane
                  tab={
                    isUsingJpRuby ? (
                      <ruby>
                        得点<Rt className="text-super-tiny">とくてん</Rt>
                      </ruby>
                    ) : (
                      <ruby>{t('得点')}</ruby>
                    )
                  }
                  key="1"
                >
                  <ScoreRadarChart
                    title=""
                    data={scoreData}
                    forPrinting={false}
                    border={false}
                    padding={1}
                    outerRadius={90}
                    size={{
                      height: 250,
                      width: '100%',
                    }}
                    hasPrevData={false}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>

          <div className="w-7/20 relative">
            <Row className={`pt-4 ${isUsingJp ? '' : ' pb-4'}`}>
              <div className={`w-40 mb-5 ${titleClassName}`}>
                {isUsingJpRuby ? (
                  <>
                    <ruby>
                      体力要素
                      <rt className="font-normal text-xxs">たいりょくようそ</rt>
                    </ruby>
                    と
                    <ruby>
                      評価
                      <rt className="font-normal text-xxs">ひょうか</rt>
                    </ruby>
                  </>
                ) : (
                  <ruby>{t('体力要素と評価')}</ruby>
                )}
              </div>
            </Row>
            {commentsTable2()}
          </div>

          <div
            id="core-table"
            className={`absolute bg-white z-20 p-4 rounded-10px ${
              !showScoreTable && 'hidden'
            }`}
            style={{ width: 880, height: 521 }}
          >
            <Row justify="space-between">
              <ruby className="font-black">
                {t('項目別得点表')}
                {isUsingJpRuby ? (
                  <rt className="font-normal text-xxs">
                    こうもくべつとくてんひょう
                  </rt>
                ) : null}
              </ruby>
              <CloseOutlined
                className="text-xl"
                style={{ color: '#036EB8' }}
                onClick={onShowScoreTable}
              />
            </Row>
            <Row>
              <Table
                rowKey="uid"
                columns={testData.column}
                dataSource={testData.test}
                style={{ minWidth: 828 }}
                size="small"
                rowClassName={(_, idx) => {
                  return `h-5 text-10px text-black font-bold ${
                    idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
                  }`
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }}
              />
            </Row>
            <Row justify="start" className="pt-3">
              <span className="font-black">
                <ruby className="font-black">
                  {t('総合評価基準表')}
                  {isUsingJpRuby ? (
                    <rt className="text-tiny">
                      そうごうひょうかきじゅんひょう
                    </rt>
                  ) : null}
                </ruby>
              </span>
            </Row>
            <Row>
              <Table
                rowKey="uid"
                columns={testData.standard_column}
                dataSource={testData.standard}
                style={{ minWidth: 564 }}
                size="small"
                rowClassName={(_, idx) => {
                  return `h-5 text-10px text-black font-bold ${
                    idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
                  }`
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }}
              />
            </Row>
            <Row justify="end" className="mt-2 text-black">
              {t('引用：文部科学省新体力テスト実施要項')}
            </Row>
          </div>
        </Row>
        <Row justify="center" align="middle" className="h-12">
          <Button
            type="primary"
            className="w-60 h-10"
            onClick={() => {
              history.push('/test')
            }}
          >
            <ruby>
              {t('編集')}
              {isUsingJpRuby ? <rt className="text-tiny">へんしゅう</rt> : null}
            </ruby>
          </Button>
        </Row>
      </>
    )
  }

  const referenceTable = () => {
    return (
      <div id="core-table" className="bg-white p-4 rounded-10px">
        <Row justify="center">
          <ruby className="font-black">
            {t('項目別得点表')}
            {isUsingJpRuby ? (
              <rt className="text-tiny">こうもくべつとくてんひょう</rt>
            ) : null}
          </ruby>
        </Row>

        <Row className="form-table-sp">
          <Table
            className="table-core-result"
            rowKey="uid"
            columns={testData.column}
            dataSource={testData.test}
            size="small"
            rowClassName={(_, idx) => {
              return `h-5 text-10px text-black font-bold ${
                idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
              }`
            }}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 10,
            }}
          />
        </Row>
        <Row justify="start" className="pt-3">
          <span className="font-black">
            <ruby className="font-black">
              {t('総合評価基準表')}
              {isUsingJpRuby ? (
                <Rt className="text-tiny">そうごうひょうかきじゅんひょう</Rt>
              ) : null}
            </ruby>
          </span>
        </Row>
        <Row className="form-table-sp">
          <Table
            rowKey="uid"
            columns={testData.standard_column}
            dataSource={testData.standard}
            style={{ minWidth: 564 }}
            size="small"
            rowClassName={(_, idx) => {
              return `h-5 text-10px text-black font-bold ${
                idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
              }`
            }}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 10,
            }}
          />
        </Row>
        <Row justify="end" className="mt-2 text-black">
          {t('引用：文部科学省新体力テスト実施要項')}
        </Row>
      </div>
    )
  }

  const showStateText = {
    1: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>今年</span>
          <rt className="font-normal text-14-hina">ことし</rt>
        </ruby>
        の
        <ruby>
          <span>結果</span>
          <rt className="font-normal text-14-hina">けっか</rt>
        </ruby>
      </span>
    ) : (
      t('今年の結果')
    ),
    2: t('あなたへのアドバイス'),
    3: isUsingJpRuby ? (
      <ruby>
        <span>基準表</span>
        <rt className="font-normal text-14-hina">きじゅんひょう</rt>
      </ruby>
    ) : (
      t('基準表')
    ),
  }

  const [showRating, setShowRating] = useState('grip')

  return (
    <Dashboard
      selectedMenu={TabIndex.View}
      navbar={
        <div className="theme-nav-title">{showStateText[showResult]}</div>
      }
    >
      <div className="select-show-result">
        <div
          className={`result-item ${checkActive(1) ? 'result-active' : ''}`}
          onClick={() => {
            setShowResult(1)
          }}
        >
          {showStateText[1]}
        </div>
        <div
          className={`result-item ${checkActive(2) ? 'result-active' : ''}`}
          onClick={() => {
            setShowResult(2)
          }}
        >
          {showStateText[2]}
        </div>
        <div
          className={`result-item ${checkActive(3) ? 'result-active' : ''}`}
          onClick={() => {
            setShowResult(3)
          }}
        >
          {showStateText[3]}
        </div>
      </div>
      
      <div className="flex justify-center items-start">
        <div className={`bg-gray-150 px-6 pt-2 rounded-10px flex flex-col justify-center form-result ${prefectureAverage ? 'pl-14-sp' : 'pl-7-sp'}`}>
          {checkActive(1) && resultTable()}
          {checkActive(2) && ratingTable()}
          {checkActive(3) && referenceTable()}
        </div>
      </div>
    </Dashboard>
  )
}

export default TestResultPage
