import { Form, InputNumber, message } from 'antd'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useTranslation } from 'react-i18next'
import Api from '~/utils/api'
import { lastMonth, thisYear } from '~/utils/constants'
import { LoadingSpin } from '../../loading-spin'
import { menuButton } from '../shared/utils'
import styles from '../style.module.scss'
import { getHandballThrowRules } from '~/components/inputs/test-input/utils/input'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

export const BallThrow = ({
  userId,
  dateTime,
  activatedTab,
  tabKey,
  isUsingJpRuby,
}) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [active, setActive] = useState<number>(0)
  const [gripData, setGripData] = useState([])
  const [graphMonth, setGraphMonth] = useState<any>([])
  const [graphYear, setGraphYear] = useState<any>([])
  const [month, setMonth] = useState<number>(lastMonth + 1)
  const [year, setYear] = useState<number>(thisYear)
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)
  const [sportsValue, setSportValues] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)

  const [disabled, setDisabled] = useState<boolean>(true)
  const lineChart = useRef<ChartJSOrUndefined<'line', number[], string> | null>(
    undefined,
  )

  const [daysInMonth, setDaysInMonth] = useState<any>([])
  const [monthsWithYear, setMonthsWithYear] = useState<any>([])

  useEffect(() => {
    const monthDay = []
    const dateMonth = new Date(year, month, 0).getDate()
    for (let x = 1; x <= dateMonth; x++) {
      monthDay.push({
        date: month + '/' + x,
        key: x,
      })
    }
    setDaysInMonth(monthDay)
  }, [month, year])
  useEffect(() => {
    const yearMonth = []
    for (let x = 1; x <= 12; x++) {
      yearMonth.push({
        date: year + '/' + x,
        key: x,
      })
    }
    setMonthsWithYear(yearMonth)
  }, [year])

  useEffect(() => {
    if (!activatedTab) {
      setActive(0)
      form.resetFields()
      form.setFieldsValue({
        handBallThrow1: sportsValue?.handBallThrow1 || '',
        handBallThrow2: sportsValue?.handBallThrow2 || '',
      })

      if (sportsValue?.studentID) {
        setDisabled(false)
      } else setDisabled(true)
    }
  }, [activatedTab])

  useEffect(() => {
    if (userId) {
      getHandballThrowData()
      getHandballThrowMonth()
      getHandballThrowYear()
      getValueData()
    }
  }, [active, userId, month, year])

  useEffect(() => {
    if (sportsValue) {
      form.setFieldsValue({
        handBallThrow1: sportsValue.handBallThrow1 || '',
        handBallThrow2: sportsValue.handBallThrow2 || '',
      })
      setDisabled(false)
    }
  }, [sportsValue])

  const getValueData = async () => {
    setLoading(true)
    const apiUrl = `/alpha/v1/student/getValueTrainingSport/${userId}?trainingSport=${tabKey}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setSportValues(dataTraining.data)
    }
    setLoading(false)
  }

  const getHandballThrowData = async () => {
    setLoading(true)
    const apiUrl = `/alpha/v1/student/handballThrowInputHistory/${userId}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGripData(dataTraining.data)
    }
    setLoading(false)
  }

  const getHandballThrowMonth = async () => {
    setLoading(true)
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/handballThrowDataGraphMonth/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphMonth(dataTraining.data)
    }
    setLoading(false)
  }

  const getHandballThrowYear = async () => {
    setLoading(true)
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/handballThrowDataGraphYear/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphYear(dataTraining.data)
    }
    setLoading(false)
  }

  const onClickLeftArrow = async () => {
    setLoading(true)
    if (active === 0) {
      setMonth(month < 2 ? 12 : month - 1)
      setYear(month < 2 ? year - 1 : year)
    } else {
      setYear(year - 1)
    }
    setLoading(false)
  }

  const onClickRightArrow = async () => {
    setLoading(true)
    if (active === 0) {
      setMonth(month > 11 ? 1 : month + 1)
      setYear(month > 11 ? year + 1 : year)
    } else {
      setYear(year + 1)
    }
    setLoading(false)
  }

  const onFinish = async (data: any) => {
    const submitData = {
      ...data,
      handBallThrow1: data.handBallThrow1 || 0,
      handBallThrow2: data.handBallThrow2 || 0,
      studentID: userId,
      date: dateTime,
    }

    try {
      const apiUrl = '/alpha/v1/student/handballThrowInput/'
      await Api.post(apiUrl, submitData)

      setSubmitSuccess(true)
      getHandballThrowData()
      getHandballThrowMonth()
      getHandballThrowYear()
      getValueData()

      setTimeout(() => {
        setSubmitSuccess(false)
      }, 5000)
    } catch (err) {
      console.error('/alpha/v1/student/ - test subject', err)
      message.error(t('エラーが発生しました。'))
    }
    form.resetFields()
  }

  const graphRecordLastestMonth = graphMonth?.dataLastest?.length
    ? graphMonth?.dataLastest?.map((item: any) => item.value)
    : 0
  const graphRecordLastestYear = graphYear?.dataLastest?.length
    ? graphYear?.dataLastest?.map((item: any) => item.value)
    : 0
  const graphRecordMaxMonth = graphMonth?.dataMax?.length
    ? graphMonth?.dataMax?.map((item: any) => item.maxValue)
    : 0
  const graphRecordMaxYear = graphYear?.dataMax?.length
    ? graphYear?.dataMax?.map((item: any) => item.maxValue)
    : 0

  const dataChartMonth: any = {}

  const dataChartYear: any = {}

  graphMonth?.dataGraph?.forEach((item: any) => {
    dataChartMonth[moment(item.date).format('M/D')] = item.value
  })

  const parseGraphYear = graphYear?.dataGraph
    ?.filter((item: any) => item.value !== 0)
    .map((val: any) => ({
      ...val,
      date: moment(val.date).format('YYYY/M'),
    }))

  const arrGraphYear = _.map(_.groupBy(parseGraphYear, 'date'), (g) =>
    _.maxBy(g, 'value'),
  )

  arrGraphYear?.forEach((item: any) => {
    dataChartYear[item?.date] = item?.value
  })

  const daysInMonthLabel = daysInMonth.map((item) => item?.date)

  const monthsInYearLabel = monthsWithYear.map((item) => item?.date)

  const firstMonthIndex = daysInMonth.findIndex(item => !!dataChartMonth[item.date])
  const lastMonthIndex = daysInMonth.findLastIndex(item => !!dataChartMonth[item.date])
  const firstYearIndex = monthsWithYear.findIndex(item => !!dataChartYear[item.date])
  const lastYearIndex = monthsWithYear.findLastIndex(item => !!dataChartYear[item.date])
  const monthGraph = daysInMonth
    .filter((item, index) => index < firstMonthIndex || index > lastMonthIndex || (dataChartMonth[item.date] && index >= firstMonthIndex && index <= lastMonthIndex))
    .map((item) => ({x: item.date, y: dataChartMonth[item.date] || 0}))
  const yearGraph = monthsWithYear
    .filter((item, index) => index < firstYearIndex || index > lastYearIndex || (dataChartYear[item.date] && index >= firstYearIndex && index <= lastYearIndex))
    .map((item) => ({x: item.date, y: dataChartYear[item.date] || 0}))

  const data: any = {
    labels: active === 0 ? daysInMonthLabel : monthsInYearLabel,
    datasets: [
      {
        data: active === 0 ? monthGraph : yearGraph,
        borderColor: '#036EB8',
        backgroundColor: '#036EB8',
        radius: (ctx) => (ctx.parsed.y === 0 ? 0 : 4)
      },
    ],
  }

  const items = [
    {
      label: t('1回目'),
      name: 'handBallThrow1',
      suffix: 'm',
      rules: getHandballThrowRules(t, isUsingJpRuby),
    },
    {
      label: t('2回目'),
      name: 'handBallThrow2',
      suffix: 'm',
      rules: getHandballThrowRules(t, isUsingJpRuby),
    },
  ]

  const selectedMonth = month - 1
  const selectedYear = year

  const onValuesChange = (a: any, total: any) => {
    if (
      (total.handBallThrow1 !== null && total.handBallThrow1 !== undefined) ||
      (total.handBallThrow2 !== null && total.handBallThrow2 !== undefined)
    ) {
      setDisabled(false)
    } else setDisabled(true)
  }

  const mOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'average',
        yAlign: 'bottom',
        backgroundColor: '#fff',
        bodyColor: '#036EB8',
        borderColor: '#FFC833',
        borderWidth: 3,
        cornerRadius: 5,
        displayColors: false,
        caretPadding: 5,
        caretSize: 10,
        padding: 15,
        callbacks: {
          title: () => null,
          label: (context: any) =>
            `${context.label} ${context.formattedValue}m`,
        },
      },
    },
    scales: {
      y: {
        grace: gripData?.length ? '30%' : 0,
        max: gripData?.length ? null : 100,
        beginAtZero: true,
        grid: {
          borderDash: [1, 1],
          drawTicks: false,
          color: (context: any) => {
            if (context.tick.value === 0) {
              return 'rgba(0, 0, 0, 0)'
            }
            return 'rgba(0, 0, 0, 0.1)'
          },
        },
        position: 'left',
        ticks: {
          color: '#C4C4C4',
          padding: 8,
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
      x: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: '#C4C4C4',
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
    },
  }

  return (
    <div>
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
        {loading ? (
          <LoadingSpin />
        ) : (
          <div className={styles.trainingWrapper}>
            <div className={styles.inputContainer}>
              <div className={styles.inputHeader}>
                {isUsingJpRuby ? (
                  <span>
                    <ruby>
                      <span>測定</span>
                      <rt className="font-normal text-xxs">そくてい</rt>
                    </ruby>
                    を
                    <ruby>
                      <span>記録</span>
                      <rt className="font-normal text-xxs">きろく</rt>
                    </ruby>
                    する
                  </span>
                ) : (
                  t('測定を記録する')
                )}
              </div>
              {submitSuccess && (
                <div className={styles.inputStatusButton}>
                  <span>{t('編集完了')}</span>
                </div>
              )}
              <div className={styles.inputBody}>
                <div className={styles.container}>
                  {items.map((item, idx) => (
                    <div key={idx} className="flex flex-col">
                      <span className={styles.itemLabel}>{item.label}</span>
                      <div className="flex">
                        <Form.Item
                          style={{ maxWidth: '88px' }}
                          className="mb-0-f"
                          name={item.name}
                          rules={item.rules as any}
                        >
                          <InputNumber
                            className="border-primary"
                            type="number"
                            inputMode="decimal"
                            onKeyDown={(e) => {
                              if (e.key === '-' || e.key === '+') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </Form.Item>
                        {item.suffix && (
                          <div className={styles.textSuffix}>
                            <span>{item.suffix}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.inputFooter}>
                <Form.Item>
                  <button type="submit" disabled={disabled}>
                    {sportsValue?.studentID ? (
                      isUsingJpRuby ? (
                        <ruby>
                          修正
                          <rt className="text-tiny">しゅうせい</rt>
                        </ruby>
                      ) : (
                        '修正'
                      )
                    ) : isUsingJpRuby ? (
                      <ruby>
                        登録
                        <rt className="text-tiny">とうろく</rt>
                      </ruby>
                    ) : (
                      t('登録')
                    )}
                  </button>
                </Form.Item>
              </div>
            </div>

            <div className={styles.chartContainer}>
              <div className={styles.chartHeader}>{t('グラフ')}</div>
              <div className={styles.chartDescription}>
                <div className={styles.chartLeftContent}>
                  {menuButton.map((text, idx: number) => (
                    <div
                      className={styles.switchButton}
                      key={idx}
                      style={idx === active ? { background: '#fff' } : {}}
                      onClick={() => setActive(idx)}
                      role="presentation"
                    >
                      <span>{t(text)}</span>
                    </div>
                  ))}
                </div>

                <div className={styles.chartRightContent}>
                  <div className={styles.headerContent}>
                    <span>
                      {isUsingJpRuby ? (
                        <ruby>
                          <span>最新記録</span>
                          <rt className="font-normal text-xxs">
                            さいしんきろく
                          </rt>
                        </ruby>
                      ) : (
                        t('最新記録')
                      )}
                    </span>
                    <span>
                      {isUsingJpRuby ? (
                        <ruby>
                          <span>最高記録</span>
                          <rt className="font-normal text-xxs">
                            さいこうきろく
                          </rt>
                        </ruby>
                      ) : (
                        t('最高記録')
                      )}
                    </span>
                  </div>
                  <div className={styles.bodyContent}>
                    <span>{`${
                      active === 0
                        ? graphRecordLastestMonth
                        : graphRecordLastestYear
                    }m`}</span>
                    <span>{`${
                      active === 0 ? graphRecordMaxMonth : graphRecordMaxYear
                    }m`}</span>
                  </div>
                </div>
              </div>

              <div className={styles.lineChart}>
                <Line
                  ref={lineChart}
                  height="300px"
                  data={data}
                  options={mOptions as any}
                />
                {(active === 0
                  ? selectedMonth >= lastMonth
                  : selectedYear > thisYear) && (
                  <div
                    onClick={onClickLeftArrow}
                    className="leftArrow"
                    role="presentation"
                  >
                    <img
                      className="imageChangeDate"
                      width={12}
                      height={12}
                      src="assets/images/prev-icon.svg"
                      alt=""
                    />
                  </div>
                )}
                {(active === 0
                  ? selectedMonth < lastMonth
                  : selectedYear < thisYear) && (
                  <div
                    id="rightClickArrow"
                    onClick={onClickRightArrow}
                    className="rightArrow"
                    role="presentation"
                  >
                    <img
                      className="imageChangeDate"
                      width={12}
                      height={12}
                      src="assets/images/next-icon.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.listContainer}>
              <div className={styles.listHeader}>
                {isUsingJpRuby ? (
                  <span>
                    <ruby>
                      測定日
                      <rt>そくていび</rt>
                    </ruby>
                  </span>
                ) : (
                  <span>{t('測定日')}</span>
                )}
                {isUsingJpRuby ? (
                  <span>
                    <ruby>
                      記録
                      <rt>きろく</rt>
                    </ruby>
                  </span>
                ) : (
                  <span>{t('記録')}</span>
                )}
              </div>
              {gripData.map((data, index: number) => (
                <div key={index} className={styles.listContent}>
                  <span>{moment(data.date).format('M/D')}</span>
                  <span>{`${data.value}m`}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}
