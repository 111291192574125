import create from 'zustand'
import { IStudentMe } from '~/utils/types/api/students'

type Me = {
  email: string
  familyName: string
  familyNameHiragana: string
  givenName: string
  givenNameHiragana: string
  role: string
  testYear: number
  _id: string
  schoolClass: number
  schoolGrade: number
}

export type School = {
  _id: string
  attributes: {
    schoolCode: string
    schoolName: string
    schoolPostalCode: number
    prefectureCode: number
    schoolAddress: string
    cityName: string
    districtName: string
    schoolCategoryCode: string
    schoolTypeCode: number
    schoolAdminName: string
    schoolAdminEmail: string
    onboardComplete: boolean
    isStudentInputActive?: boolean
    videoQualitySetting?: boolean
  }
}

interface IStudentStore {
  me: IStudentMe | null
  teacher?: Me
  school: School | null
  schoolCategoryCode: string | null
  isLoading: boolean
  warnBeforeLeave: boolean
  setMe: (me: IStudentMe) => void
  setSchool: (school: School) => void
  setIsLoading: (isLoading: boolean) => void
  setWarnBeforeLeave: (warn: boolean) => void
}

const useAlphaStore = create<IStudentStore>((set) => ({
  me: null,
  school: null,
  schoolCategoryCode: null,
  isLoading: false,
  warnBeforeLeave: false,
  setMe: (me: IStudentMe) => set({ me }),

  setSchool: (school: School) =>
    set({ school, schoolCategoryCode: school?.attributes?.schoolCategoryCode }),

  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setWarnBeforeLeave: (warn: boolean) => set({ warnBeforeLeave: warn }),
}))

export { useAlphaStore }
